var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Bootstrap Navs")]),
              _c("div", { staticClass: "card-header-actions" }, [
                _c(
                  "a",
                  {
                    staticClass: "card-header-action",
                    attrs: {
                      href: "https://coreui.io/vue/docs/components/nav",
                      rel: "noreferrer noopener",
                      target: "_blank"
                    }
                  },
                  [_c("small", { staticClass: "text-muted" }, [_vm._v("docs")])]
                )
              ])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c(
                "CNav",
                [
                  _c("CNavItem", { attrs: { active: "" } }, [_vm._v("Active")]),
                  _c("CNavItem", { attrs: { title: "Link" } }),
                  _c("CNavItem", [_vm._v("Another Link")]),
                  _c("CNavItem", { attrs: { disabled: "" } }, [
                    _vm._v("Disabled")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Bootstrap Navs ")]),
              _c("small", [_vm._v("icons")])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c(
                "CNav",
                { attrs: { variant: "pills" } },
                [
                  _c(
                    "CNavItem",
                    { attrs: { active: "" } },
                    [_c("CIcon", { attrs: { name: "cil-basket" } })],
                    1
                  ),
                  _c(
                    "CNavItem",
                    [_c("CIcon", { attrs: { name: "cil-settings" } })],
                    1
                  ),
                  _c(
                    "CNavItem",
                    [_c("CIcon", { attrs: { name: "cil-bell" } })],
                    1
                  ),
                  _c(
                    "CNavItem",
                    { attrs: { disabled: "" } },
                    [_c("CIcon", { attrs: { name: "cil-envelope-closed" } })],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Bootstrap Navs ")]),
              _c("small", [_vm._v("tab style")])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c(
                "CNav",
                { attrs: { variant: "tabs" } },
                [
                  _c("CNavItem", { attrs: { active: "" } }, [
                    _vm._v(" Active ")
                  ]),
                  _c("CNavItem", [_vm._v(" Link ")]),
                  _c("CNavItem", [_vm._v(" Another Link ")]),
                  _c("CNavItem", { attrs: { disabled: "" } }, [
                    _vm._v("Disabled")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            {
              on: {
                click: function($event) {
                  _vm.item++
                }
              }
            },
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Bootstrap Navs ")]),
              _c("small", [_vm._v("pill style")])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c(
                "CNav",
                { attrs: { variant: "pills" } },
                [
                  _c("CNavItem", { attrs: { active: "" } }, [_vm._v("Active")]),
                  _c("CNavItem", [_vm._v("Link")]),
                  _c("CNavItem", [_vm._v("Another Link")]),
                  _c("CNavItem", { attrs: { disabled: "" } }, [
                    _vm._v("Disabled")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Bootstrap Navs ")]),
              _c("small", [_vm._v("fill tabs")])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c(
                "CNav",
                { attrs: { fill: "", variant: "tabs" } },
                [
                  _c("CNavItem", { attrs: { active: "" } }, [_vm._v("Active")]),
                  _c("CNavItem", [_vm._v("Link")]),
                  _c("CNavItem", [_vm._v("Link with a long name ")]),
                  _c("CNavItem", { attrs: { disabled: "" } }, [
                    _vm._v("Disabled")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Bootstrap Navs ")]),
              _c("small", [_vm._v("justified tabs")])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c(
                "CNav",
                { attrs: { justified: "", variant: "tabs" } },
                [
                  _c("CNavItem", { attrs: { active: "" } }, [_vm._v("Active")]),
                  _c("CNavItem", [_vm._v("Link")]),
                  _c("CNavItem", [_vm._v("Link with a long name ")]),
                  _c("CNavItem", { attrs: { disabled: "" } }, [
                    _vm._v("Disabled")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Bootstrap Navs ")]),
              _c("small", [_vm._v("dropdown support")])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c(
                "CNav",
                { attrs: { pills: "" } },
                [
                  _c("CNavItem", { attrs: { active: "" } }, [_vm._v("Active")]),
                  _c("CNavItem", [_vm._v("Link")]),
                  _c(
                    "CDropdown",
                    {
                      attrs: {
                        id: "nav7_ddown",
                        nav: "",
                        placement: "bottom-end",
                        "button-content": "Dropdown"
                      }
                    },
                    [
                      _c("CDropdownItem", [_vm._v("one")]),
                      _c("CDropdownItem", [_vm._v("two")]),
                      _c("CDropdownDivider"),
                      _c("CDropdownItem", [_vm._v("three")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Bootstrap Navs ")]),
              _c("small", [_vm._v("vertical variation")])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c(
                "CRow",
                [
                  _c(
                    "CCol",
                    { staticClass: "m-0", attrs: { col: "3" } },
                    [
                      _c(
                        "CNav",
                        { attrs: { vertical: "", pills: "" } },
                        [
                          _c("CNavItem", { attrs: { active: "" } }, [
                            _vm._v("Active")
                          ]),
                          _c("CNavItem", [_vm._v("Link")]),
                          _c("CNavItem", [_vm._v("Another Link")]),
                          _c("CNavItem", { attrs: { disabled: "" } }, [
                            _vm._v("Disabled")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }